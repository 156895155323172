import { selector } from "recoil";
import { LanguageState } from ".";

const FilteredLanguages = selector({
    key: 'filteredLanguages',
    get: ({get}) => {
      const { locale, locales } = get(LanguageState);
        
      let _messages = locales.filter(language => language.locale === locale)[0].messages;
      return _messages;
    },
  });

export default FilteredLanguages;