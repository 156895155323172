import * as React from "react"

const Discord = (props : any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    clipRule="evenodd"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit={2}
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M441.482 116.729s-53.154-41.662-116.046-46.45l-5.587 11.333c56.826 13.887 82.845 33.84 110.14 58.262-46.929-23.943-93.38-46.45-174.148-46.45s-127.22 22.507-174.15 46.45c27.296-24.422 58.263-46.61 110.14-58.262l-5.586-11.333c-65.924 6.225-116.046 46.45-116.046 46.45S10.819 202.925.603 372.126c59.859 69.117 150.844 69.595 150.844 69.595l18.995-25.38c-32.244-11.173-68.797-31.286-100.243-67.52 37.511 28.413 94.337 58.103 185.801 58.103s148.13-29.53 185.801-58.103c-31.445 36.234-68 56.347-100.243 67.52l18.995 25.38s90.985-.478 150.844-69.595c-10.535-169.2-69.915-255.397-69.915-255.397zM180.18 325.676c-22.507 0-40.704-20.751-40.704-46.45s18.197-46.451 40.704-46.451 40.704 20.75 40.704 46.45-18.197 46.45-40.704 46.45zm151.004 0c-22.507 0-40.704-20.751-40.704-46.45s18.197-46.451 40.704-46.451 40.703 20.75 40.703 46.45-18.356 46.45-40.703 46.45z"
      fill="#fff"
      fillRule="nonzero"
    />
  </svg>
)

export default Discord;
