
interface Locales {
  locale: string;
  messages: Promise<any>;
}

const locales : Locales[] = [
  {
    locale: 'it',
    messages: import('./it'),
    //loadData: import(`@formatjs/intl-relativetimeformat/dist/locale-data/it`),
  },
  {
    locale: 'en',
    messages: import('./en'),
    //loadData: import(`@formatjs/intl-relativetimeformat/dist/locale-data/en`),
  },
];

export default locales;
