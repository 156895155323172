import { Box } from "@mui/material";
import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import LandingPage from "../pages/LandingPage/LandingPage";
import CookieConsent from '../components/CookieConsent/CookieConsent';

function RouteProvider() {
  const location = useLocation();
  const [value, setValue] = useState<string>(location.pathname);
 
  return (
    <Box sx={{ width: 1, height: "100%", display: 'flex', flexDirection: 'row', flex: 1}}>
      <Box sx={{ width: 1, height: "100%", display: 'flex', flexDirection: 'column', flex: 1 }}>
        {/* <Header value={value} setValue={setValue} /> */}
        <CookieConsent />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/game" element={<div>the game</div>} />
          <Route
            path="*"
            element={<Navigate to="/" state={{ from: location }} replace />}
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default RouteProvider;
