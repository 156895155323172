import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ReactPixel from "react-facebook-pixel";
import TagManager from 'react-gtm-module';
import CookieSettingDialog from "./CookieSettingDialog";

const options = {
    autoConfig: true,
    debug: false,
};

interface tagManager {
    gtmId: string;
    events: {
        navigation: string;
        social: string;
        subscribe: string;
        presaleRedirect: string;
    };
}

const tagManagerArgs : tagManager = {
  gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
  events: {
    navigation: 'Navigation',
    social: 'Social',
    subscribe: 'Subscribe',
    presaleRedirect: 'Presale redirect'
  }
}

const CookieConsent = () => {
    const [cookies, setCookies] = useCookies([
        "consentCookie",
        "analyticalCookie",
        "marketingCookie",
    ]);
    const [openCookieSettings, setOpenCookieSettings] = useState<boolean>(cookies.consentCookie ? false : true);

    useEffect(() => {
        if (cookies.analyticalCookie && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            console.debug("GA - Initialize");
            TagManager.initialize(tagManagerArgs)
        }
        if (cookies.analyticalCookie && process.env.REACT_APP_PIXEL_ID) {
            console.debug("PIXEL - Initialize");
            ReactPixel.grantConsent();
            ReactPixel.init(process.env.REACT_APP_PIXEL_ID, undefined, options);
            ReactPixel.pageView();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (cookies.consentCookie) {

        return null;
    }

    const handleAcceptCookie = (analytics?: boolean, advertisement?: boolean) => {

        setCookies("consentCookie", true);
        if(analytics)
        setCookies("analyticalCookie", true);
        if(advertisement)
        setCookies("marketingCookie", true);

        if (analytics && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            console.debug("GA - Initialize");
            TagManager.initialize(tagManagerArgs)
        }
        if (analytics && process.env.REACT_APP_PIXEL_ID) {
            console.debug("PIXEL - Initialize");
            ReactPixel.grantConsent();
            ReactPixel.init(process.env.REACT_APP_PIXEL_ID, undefined, options);
            ReactPixel.pageView();
        }
    };

    return (
        <CookieSettingDialog open={openCookieSettings} onClose={() => setOpenCookieSettings(false)} onSave={handleAcceptCookie} />
    );
};

export default CookieConsent;
