import React from "react";
import { Box, useTheme, useMediaQuery, Grid, Typography } from "@mui/material";
import FirstRound from "./Round/Round";
import FirstSection from "./FirstSection/FirstSection";
import Megachickens from "./Megachickens/Megachickens";
// import Builders from "./Builders/Builders";
import Gardens from "./Gardens/Gardens";
import TournamentRooms from "./TournamentRoom/TournamentRoom";
import Footer from '../../components/Footer/Footer';
import Game from "./Game/Game";
import Evolutions from "./Evolutions/Evolutions";
import Presales from './Presales/Presales';
import Builders from "./Builders/Builders";
import Logo from "../../assets/logo-no-full.webp";

function LandingPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
        background: theme.customColors?.background,
      }}
    >

        <Grid container height={'100%'}>
          <Grid item xs={12} pb={2} width={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <img src={Logo} alt="logo" width={425} style={{ marginLeft: 'auto', marginRight: 'auto', paddingBottom: 16 }} />
          </Grid>
          <Grid item xs={12} pb={2} width={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            
          <Typography fontWeight="bold" variant="h1" component="h1" color="white" textAlign={"center"}>The chickens are coming...</Typography>
          </Grid>
        </Grid>
      {/* <FirstSection /> */}
      {/* <FirstRound isMobile={isMobile} roundSection={1}/>
      <Game isMobile={isMobile} />
      <Evolutions isMobile={isMobile} />
      <Presales isMobile={isMobile} />
      <Megachickens isMobile={isMobile} />
      <Builders isMobile={isMobile} />
      <Gardens isMobile={isMobile} />
      <TournamentRooms isMobile={isMobile} />
      <FirstRound isMobile={isMobile} roundSection={2}/> */}
      <Footer />
    </Box>
  );
}

export default LandingPage;
