import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogContent, FormControlLabel, Grid, styled, Switch, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const websiteCookiesSection = [
    {
        title: "Necessary",
        id: "consentManagement",
        description: "Megachickens uses cookies and similar technologies, some of them are necessary to provide you with a smooth and efficient experience.",
    },
    {
        title: "Analytics",
        id: "analytics",
        description: "Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc."
    },
    {
        title: "Advertisement",
        id: "advertisement",
        description: "These technologies are used for things like personalised ads, to limit how many times you see an ad, to understand usage via Google Analytics, to understand how you got to our web properties, to ensure that we understand the audience and can provide relevant ads We do this with social media, marketing, and analytics partners (who may have their own information they’ve collected). Saying no will not stop you from seeing our ads, but it may make them less relevant or more repetitive."
    },
];

const CookieSettingContainer = styled(Dialog)(({ theme }) => ({
    padding: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    zIndex: 999,
}));

const CookieSettingContent = styled(DialogContent)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
}));

const CookieSettingActions = styled(DialogContent)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    fontFamily: theme.typography.fontFamily,
}));

const CookieSecondaryAcceptButton = styled(Button)(({ theme }) => ({
    color: theme.customColors?.background,
    borderColor: theme.customColors?.background,
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
        backgroundColor: "#CCC",
    },
    marginLeft: theme.spacing(2),
}));

const CookieAcceptButton = styled(Button)(({ theme }) => ({
    color: "#FFF",
    backgroundColor: theme.customColors?.background,
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
        backgroundColor: theme.customColors?.background,
    },
    marginLeft: theme.spacing(2),
}));

interface CookieSettingDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (analytics:boolean, advertisement:boolean) => void;
}

const CookieSettingDialog = ({ open, onClose, onSave }: CookieSettingDialogProps) => {
    const [allowAnalytics, setAllowAnalytics] = useState<boolean>(false);
    const [allowAdvertisement, setAllowAdvertisement] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        event.preventDefault();
        if(index === 1 ){
            setAllowAnalytics(event.target.checked);
        }else{
            setAllowAdvertisement(event.target.checked);
        }
    }

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleSave = (acceptAll: boolean) => {
        if(acceptAll){
            onSave(true, true);
            onClose();
        }else{
            onSave(allowAnalytics, allowAdvertisement);
            onClose();
        }
    }

    const handleClose = (event : object, reason : string) => {
        if(reason === 'backdropClick'){
            return;
        }
        onClose();
    }

    return (
        <CookieSettingContainer open={open} onClose={handleClose} disableEscapeKeyDown>
            <CookieSettingContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">Privacy Overview</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            This website uses cookies to improve your experience while you
                            navigate through the website. Out of these, the cookies that are
                            categorized as necessary are stored on your browser as they are
                            essential for the working of basic functionalities of the website.
                            We also use third-party cookies that help us analyze and
                            understand how you use this website. These cookies will be stored
                            in your browser only with your consent. You also have the option
                            to opt-out of these cookies. But opting out of some of these
                            cookies may affect your browsing experience.
                            You can find more information about our use of cookies and similar technologies in our <a href="https://www.iubenda.com/privacy-policy/18380187/cookie-policy" target="_blank" rel="noreferrer">Cookie policy</a>.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        {websiteCookiesSection.map((el, index) => (
                            <Accordion expanded={expanded === el.id} onChange={handleChange(el.id)} key={el.id} sx={{ backgroundColor: '#EFEFEF' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ flexDirection: 'row-reverse' }}>
                                    <Typography sx={{ pl: 2 }} variant="body1" display="flex" alignItems="center">{el.title}</Typography>
                                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                                        {index === 0 && <Typography variant="subtitle2">Always enabled</Typography>}
                                        {index !== 0 && <FormControlLabel disableTypography labelPlacement="start" control={<Switch onChange={event => handleChangeSwitch(event, index)} />} label={<Typography variant="subtitle2">{((index === 1 && allowAnalytics) || (index === 2 && allowAdvertisement)) ? "Enabled" : "Disabled"}</Typography>}/>}
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body2">{el.description}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </CookieSettingContent>
            <CookieSettingActions dividers>
                <CookieSecondaryAcceptButton onClick={() => handleSave(false)}>Reject</CookieSecondaryAcceptButton>
                <CookieSecondaryAcceptButton onClick={() => handleSave(false)}>Accept selected</CookieSecondaryAcceptButton>
                <CookieAcceptButton onClick={() => handleSave(true)}>Accept all</CookieAcceptButton>
            </CookieSettingActions>
        </CookieSettingContainer>
    );
};

export default CookieSettingDialog;
