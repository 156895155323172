import { atom } from 'recoil';
import config from '../../config/config';

const languageAtom = atom({
    key: 'locales',
    default: {
        defaultLocale: config.locale.defaultLocale,
        locale: localStorage.getItem('locale') || config.locale.defaultLocale,
        locales: [...config.locale.locales]
    }
});

export default languageAtom;