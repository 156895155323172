import { createTheme, PaletteOptions, responsiveFontSizes } from '@mui/material';

declare module "@mui/material/styles" {
  interface Theme {
    palette?: {
        primary?: {
            main?: string;
        }
    },
    customColors?: {
      background?: string;
      backgroundSecondary?: string;
      dangerColor?: string;
      backgroundGradient?: string;
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    palette?: PaletteOptions;
    customColors?: {
      background?: string;
      backgroundSecondary?: string;
      dangerColor?: string;
      backgroundGradient?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary:{
        // main: '#FFC83D'
        main: '#EFA513'
    }
  },
  customColors: {
    background: "#1D2028",
    backgroundSecondary: "#252733",
    dangerColor: '#d02121',
    backgroundGradient: "linear-gradient(180deg, #F3681A 0%, #EFD912 100%)"
  },
  components:{
    MuiButton:{
      styleOverrides:{
        outlined: {
          border: '2px solid #EFA513'
        }
      }
    },
    MuiGrid:{
      styleOverrides:{
        root:{
          marginTop: 0
        }
      }
    },
    MuiTypography:{
      styleOverrides:{
        h4: {
          '@media (max-width:600px)': {
            fontSize: '1.5rem',
          }
        }
      }
    }
  }
});

export default responsiveFontSizes(theme);