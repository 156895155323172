import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { CircularProgress, ThemeProvider } from "@mui/material";
import theme from "./theme";
import RouteProvider from "./config/Routes";
import { IntlProvider } from "react-intl";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { FilteredLanguages, LanguageState } from "./recoil/language";
import { CookiesProvider } from "react-cookie";

function LanguageProvider({ children }: any) {
  const [{ locale, defaultLocale }] = useRecoilState(LanguageState);
  const messages = useRecoilValue(FilteredLanguages);

  return (
    <IntlProvider
      messages={messages.default}
      locale={locale}
      defaultLocale={defaultLocale}
    >
      {children}
    </IntlProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<CircularProgress />}>
      <RecoilRoot>
        <LanguageProvider>
          <CookiesProvider>
            <ThemeProvider theme={theme}>
              <Router>
                <RouteProvider />
              </Router>
            </ThemeProvider>
          </CookiesProvider>
        </LanguageProvider>
      </RecoilRoot>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
