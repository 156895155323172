import React from "react";
import { Container, Grid, styled, Typography, Divider } from '@mui/material';
import { Instagram, Telegram, Twitter } from "@mui/icons-material";
import Discord from "../../assets/icons/Discord";
import Logo from "../../assets/logo-no-full.webp";
import LogoCinecitta from "../../assets/logo_cinecitt_gamehub_white.webp";
import LogoRegioneLazio from "../../assets/logo-REGIONE-LAZIO.webp";
import { useCookies } from 'react-cookie';
import moment from "moment";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

const FooterContainer = styled("footer")(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(2),
}));

const GridItem = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Grid container item xs={3}>
    <Grid
      item
      xs={12}
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      {children}
    </Grid>
    <Grid item xs={12}>
      <Typography
        fontWeight="bold"
        variant="body1"
        color="#FFF"
        textAlign="center"
      >
        {title}
      </Typography>
    </Grid>
  </Grid>
);

function Footer() {
  const [cookies] = useCookies(["analyticalCookie"]);

  const handlePressSocial = (social: string) => {
    if (cookies.analyticalCookie && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      TagManager.dataLayer({
          dataLayer: {
            event: 'social',
            action: 'Clicked ' + social + ' on ' + moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
            label: 'Social icon' + social
        }
      });
      console.debug("GA - Sent");
    }
    if (cookies.analyticalCookie && process.env.REACT_APP_PIXEL_ID) {
      ReactPixel.track('Social Icon', { social: social, date: moment(new Date()).format('DD/MM/YYYY HH:mm:ss') });
      console.debug("PIXEL - Sent");
    }
  }

  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Grid container>
          <GridItem title="Telegram">
            <a href="https://t.me/+lfhPcgRylA9hOWU8" target="_blank" rel="noreferrer" onClick={() => handlePressSocial('telegram')}>
              <Telegram htmlColor="#FFF" fontSize="large" />
            </a>
          </GridItem>
          <GridItem title="Discord">
            <a href="https://discord.gg/zq2BEzFGdt" target="_blank" rel="noreferrer" onClick={() => handlePressSocial('discord')}>
              <Discord width="32" />
            </a>
          </GridItem>
          <GridItem title="Twitter">
            <a href="https://twitter.com/megachickens" target="_blank" rel="noreferrer" onClick={() => handlePressSocial('twitter')}>
              <Twitter htmlColor="#FFF" fontSize="large" />
            </a>
          </GridItem>
          <GridItem title="Instagram">
            <a href="https://www.instagram.com/megachickens_official/" target="_blank" rel="noreferrer" onClick={() => handlePressSocial('instagram')}>
              <Instagram htmlColor="#FFF" fontSize="large" />
            </a>
          </GridItem>
        </Grid>
        <Divider color="#CCC" sx={{ my: 4 }} />
        <Grid container>
          <Grid item xs={12} pb={2} width={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Typography fontWeight="bold" color="#FFF" mr={2}>POWERED BY</Typography>
          <img src={LogoCinecitta} alt="logo" width={"114px"} height={"46px"} style={{ marginRight: 16 }}/>
          <img src={LogoRegioneLazio} alt="logo" width={"140px"} height={"70px"} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontWeight="bold"
              variant="subtitle2"
              color="#CCC"
              textAlign="center"
            >
              Copyright 2022 Megachickens - <a style={{ color: '#FFF'}} onClick={() => handlePressSocial('website')} href="https://www.codemount.studio" target="_blank" rel="noreferrer">Codemount Studio</a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontWeight="bold"
              variant="subtitle2"
              color="#CCC"
              textAlign="center"
            >
              <a style={{ color: '#FFF'}} href="https://www.iubenda.com/privacy-policy/18380187" target="_blank" rel="noreferrer">Privacy policy</a> &ensp;
              <a style={{ color: '#FFF'}} href="https://www.iubenda.com/privacy-policy/18380187/cookie-policy" target="_blank" rel="noreferrer">Cookie policy</a> &ensp;
              <a style={{ color: '#FFF'}} href="https://www.iubenda.com/terms-and-conditions/18380187" target="_blank" rel="noreferrer">Terms and condition</a>
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography
              fontWeight="bold"
              variant="subtitle2"
              color="#CCC"
              textAlign="center"
            >
              ERC20/BEP20: --- STILL IN DEV ---
            </Typography>
          </Grid> */}
        </Grid>
      </Container>
    </FooterContainer>
  );
}

export default Footer;
