import locales from './locales';

const config = {
  locale: {
    locales,
    defaultLocale: 'it',
  },
  theme: {
  }
}

export default config;
